@mixin task-rules {
    @media screen and (max-width: 599px) {
        app-operator-timesheet-tasks {
            .mat-expansion-panel {
                > .mat-expansion-panel-header {
                    padding-left: 12px;
                }

                .mat-expansion-panel-content {
                    > .mat-expansion-panel-body {
                        padding: 0;
                    }
                }
            }
        }
    }
}
