// This needs to be included after the variables are defined.
@use '@angular/material' as mat;
@use "../../common/styles/common-styles";
@use '../../operator-timesheet/src/lib/operator-timesheet-tasks/operator-timesheet-tasks.global.scss' as timesheet-tasks;

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.all-component-typographies();
@include mat.core();

@include common-styles.default-table-rules();
@include common-styles.default-form-styles();
@include common-styles.shared-material-definitions();
@include timesheet-tasks.task-rules();

html {
    // Without this Mobile Safari might decide to scale up font sizes in surprising places
    -webkit-text-size-adjust: none;
}

body {
    @include mat.all-component-themes(common-styles.$finnpilot-light-theme);
    @include common-styles.theme-styles(common-styles.$finnpilot-light-theme);
    @include mat.checkbox-density(-3);
    @include mat.radio-density(-3);

    .dense-form {
        @include mat.form-field-density(-5);
    }

    .dense-form-4 {
        @include mat.form-field-density(-4);

        // form field density does not scale down icons automatically so we'll have to do that by ourselves
        .mat-mdc-form-field-icon-prefix mat-icon {
            font-size: 22px;
            height: 16px;
        }
    }
}

a {
    @apply text-blue no-underline hover:text-blue-light;
}

.focus-placeholder:not(:focus)::placeholder {
    color: transparent;
}

@media print {
    .no-print {
        display: none !important;
    }

    html {
        background-color: transparent !important; // Let's not waste ink

        body * {
            @apply font-arial;
            // Problems with Roboto font in printing
        }
    }

    h3 {
        font-size: 16px;
    }
}

@media not print {
    .print-only {
        display: none !important;
    }
}

.default-actions {
    @apply py-2 px-0 text-right;

    .mat-mdc-button-base + .mat-mdc-button-base {
        @apply ml-2;
    }
}

.report-table {
    @apply w-full border-spacing-[1px] border-collapse;

    td {
        @apply p-[1px] border border-black;
    }
}

.dialog-without-padding .mat-mdc-dialog-container {
    @apply p-0;

    > .mat-mdc-dialog-content {
        @apply p-0 m-0;
    }
}

.default-content {
    @apply m-4;
}

.default-spinner {
    @apply my-4 mx-auto;
}

.default-content-centered {
    @apply my-4 mx-auto max-w-[800px];
}

.dual-title {
    @apply inline-flex w-full justify-between;
}

.fill-remaining-space {
    flex: 1;
}

.mat-mdc-option .mdc-list-item__primary-text {
    // TODO: use explicit styles in components that depend this
    width: 100%;
}

.mat-mdc-option.hint-option {
    @apply min-h-[20px] text-sm;
}

mat-icon.small-icon {
    @apply text-[.75rem] w-3 h-3 leading-3;
}

.portnet-import-changed {
    @apply text-orange italic;
}

h3.form-section-title {
    @apply text-lg font-normal mx-0 my-1 col-start-1 col-span-full;
}

table.legacy-form-table {
    @apply border-none border-spacing-0.5 border-separate;

    td {
        @apply p-0.5 border-none align-top;

        &.label {
            @apply text-right align-top;
        }

        &.actions {
            @apply text-right;
        }
    }

    th.section-title {
        @apply text-white bg-blue text-left font-normal pl-0.5;
    }

    td.sub-section-title {
        @apply bg-gray-100 font-normal;
    }
}

.billing-table {
    @apply text-xs;

    thead th {
        @apply text-white bg-blue text-left p-0.5;
    }

    td {
        @apply p-0.5 align-top border border-black;
    }
}

.border-bottom-dashed {
    border-bottom-style: dashed;
}

.border-top-dashed {
    border-top-style: dashed;
}
