@use '@angular/material' as mat;

// Common Material-related styles for all applications

@mixin shared-material-definitions {

    // Material style guide says that buttons should be uppercased
    .mat-mdc-button-base, .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__text-label {
        text-transform: uppercase;
    }

    body {
        --mdc-text-button-label-text-transform: uppercase;
        --mdc-filled-button-label-text-transform: uppercase;
        --mdc-outlined-button-label-text-transform: uppercase;
        --mdc-protected-button-label-text-transform: uppercase;
    }

    a.mat-mdc-button:hover {
        @apply no-underline;
    }

    .mat-mdc-form-field-hint a {
        @apply text-inherit;
    }

    .mat-select-align-right .mat-mdc-select-value {
        @apply text-right mr-1;
    }

    // Make Material tooltips bigger and replace the transparent background with black.
    .mat-mdc-tooltip .mdc-tooltip__surface {
        color: white !important;
        background: black !important;
        max-width: 300px !important;
    }

    .mat-mdc-tooltip.multiline .mdc-tooltip__surface {
        white-space: pre-line !important;
    }

    mat-card-title.normal-font {
        @apply text-base font-normal;
    }

    mat-card-content.align-with-title {
        @apply px-4;
    }

    // Gray out icons inside disable form fields
    mat-form-field.mat-form-field-disabled mat-icon {
        @apply opacity-50;
    }

    @media print {
        .mat-mdc-card {
            padding-bottom: 0 !important;

            &.heading {
                padding: 16px 0 0 16px !important;
            }
        }
    }

    .mat-mdc-option.w-full .mdc-list-item__primary-text {
        width: 100%;
    }

    // Fix weird problem with Tailwind https://github.com/tailwindlabs/tailwindcss/discussions/9993
    .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
        border-right-style: hidden;
    }

    .mat-mdc-input-element {
        box-shadow: none !important;
    }

    .mat-label-italic-orange .mdc-label {
        @apply italic text-orange;
    }
}
