// Material-like table for cases where we don't use mat-table
@mixin default-table-rules {
    table.default-table {
        @apply font-sans w-full;
        border-spacing: 0;

        &.striped tr {
            @apply even:bg-gray-50;
        }

        > thead > tr > th, > tfoot > tr > th, > tfoot > tr > td, > tbody > tr > td, > tbody > tr > th {
            padding: 0;
            border-bottom: 1px solid rgba(0, 0, 0, .12);
            text-align: left;

            &:first-of-type {
                padding-left: 18px;
            }

            &:last-of-type {
                padding-right: 18px;
            }
        }

        > thead > tr, > tfoot > tr {
            height: 56px;

            > th {
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, .87);

                > button {
                    color: black; // Buttons should remain black
                }
            }
        }

        th, td {
            &:not(:last-child) {
                padding-right: 4px;
            }
        }

        > tbody > tr {
            height: 48px;

            th, td {
                color: rgba(0, 0, 0, .87);
                font-size: 14px;
            }
        }

        tr.removed-row td {
            @apply text-gray-500 line-through;
        }
    }

    td.actions-cell {
        white-space: nowrap;
        text-align: right !important;
    }
}
