@use "sass:map";
@use '@angular/material' as mat;

// Palettes generated from Finnpilot's official blue and orange with:
// http://mcg.mbitson.com/#!?finnpilotblue=%2300529c&finnpilotorange=%23f37021&themename=finnpilot

$md-finnpilot-blue: (
    50 : #e1e6e9,
    100 : #b3c1c8,
    200 : #8198a4,
    300 : #4f6f7f,
    400 : #295063,
    500 : #033148,
    600 : #032c41,
    700 : #022538,
    800 : #021f30,
    900 : #011321,
    A100 : #5da6ff,
    A200 : #2a8aff,
    A400 : #006ff6,
    A700 : #0063dd,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-finnpilot-orange: (
    50 : #feeee4,
    100 : #fbd4bc,
    200 : #f9b890,
    300 : #f79b64,
    400 : #f58542,
    500 : #f37021,
    600 : #f1681d,
    700 : #ef5d18,
    800 : #ed5314,
    900 : #ea410b,
    A100 : #ffffff,
    A200 : #ffe7e1,
    A400 : #ffbeae,
    A700 : #ffaa95,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$finnpilot-primary: mat.m2-define-palette($md-finnpilot-blue);
$finnpilot-accent: mat.m2-define-palette($md-finnpilot-orange);

$finnpilot-typography: mat.m2-define-typography-config(
    $font-family: 'Roboto, Helvetica Neue, sans-serif',
    $headline-1: mat.m2-define-typography-level($font-size: 96px, $letter-spacing: -1.5px),
    $headline-2: mat.m2-define-typography-level($font-size: 60px),
    $headline-3: mat.m2-define-typography-level($font-size: 48px),
    $headline-4: mat.m2-define-typography-level($font-size: 34px),
    $headline-5: mat.m2-define-typography-level($font-size: 24px),
    $headline-6: mat.m2-define-typography-level($font-size: 20px),
    $subtitle-1: mat.m2-define-typography-level($font-size: 16px),
    $subtitle-2: mat.m2-define-typography-level($font-size: 14px),
    $body-1: mat.m2-define-typography-level($font-size: 16px),
    $body-2: mat.m2-define-typography-level($font-size: 14px, $line-height: 24px, $font-weight: regular), // 14 px originally
    $caption: mat.m2-define-typography-level($font-size: 14px),
        //$button: mat.define-typography-level($font-size: 10px),
    $overline: mat.m2-define-typography-level($font-size: 12px),
);

$finnpilot-light-theme: mat.m2-define-light-theme((
    color: (
        primary: $finnpilot-primary,
        accent: $finnpilot-accent
    ),
    density: -1
    //    typography: $finnpilot-typography
));

$finnpilot-dark-theme: mat.m2-define-dark-theme((
    color: (
        primary: mat.$m2-indigo-palette,
        accent: mat.$m2-pink-palette
    )
));

@mixin theme-styles($theme) {
    .error {
        color: mat.m2-get-color-from-palette(map.get($theme, warn));
    }
}
